import React from 'react'
import Header from './Header'
import Navbar from "./Navbar"


const Home = () => {

    return (
        <>
           <Navbar />
           <Header />
        
        </>
    )
}

export default Home
